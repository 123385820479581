import React, { useState } from 'react';
import { TextField, Button, Snackbar, Alert, Container, AppBar, Toolbar, IconButton, Typography, Grid, useTheme, InputAdornment, Paper, Box } from '@mui/material';
import axios from 'axios';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom';

const ResetPasswordForm = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [message, setMessage] = useState('');
  const [stat, setStat] = useState('info'); // info, success, error
  const [openSnack, setOpenSnack] = useState(false);
  const query = new URLSearchParams(location.search);
  const token = query.get('token');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  };

  const handleResetPassword = () => {
    if (newPassword !== confirmPassword) {
      setMessage('Girilen şifreler birbirinden farklı.');
      setStat('error');
      setOpenSnack(true);
      return;
    }

    axios.post("https://api.youbetshop.com/resetpass", { token: token, newpass: newPassword })
      .then(response => {
        setMessage('Şifre başarıyla sıfırlandı.');
        setStat('success');
        setOpenSnack(true);
        setTimeout(() => {
          navigate('/')
        }, 2500);
      })
      .catch(error => {
        console.error('Şifre sıfırlama işlemi başarısız:', error);
        setMessage('Şifre sıfırlama işlemi başarısız.');
        setStat('error');
        setOpenSnack(true);
      });
  };

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="back" onClick={() => navigate("/")}>
            <ArrowBackIcon />
            <Typography variant="h6">
              Ana Sayfa
            </Typography>
          </IconButton>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
          </Typography>
          <Typography variant="body1" style={{ marginRight: '10px' }}>
            {"Şifre Değiştir"}
          </Typography>
        </Toolbar>
      </AppBar>
      <Container
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '40vh'
        }}
      >
        <Paper elevation={3} sx={{ padding: 4, maxWidth: 400, width: '100%' }}>
          <Box textAlign="center" marginBottom={2}>
            <Typography variant="h4" sx={{ color: "#333" }}>
              Şifre Değiştir
            </Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                margin="normal"
                label="Yeni Şifre"
                type={showPassword ? "text" : "password"}
                fullWidth
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="normal"
                label="Şifreyi Onayla"
                type={showConfirmPassword ? "text" : "password"}
                fullWidth
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                        edge="end"
                      >
                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleResetPassword}
                fullWidth
              >
                Şifreyi Değiştir
              </Button>
            </Grid>
          </Grid>
        </Paper>
        <Snackbar
          open={openSnack}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={stat}
            sx={{ width: '100%' }}
          >
            {message}
          </Alert>
        </Snackbar>
      </Container>
    </>
  );
};

export default ResetPasswordForm;
