import React, { useState,useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Alert, IconButton, InputAdornment,Grid } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import axios from 'axios';

const RegisterDialog = ({ open, onClose,promocodes }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [openSnack, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [promocode2,setPromoCodes] = useState('');
  const [stat, setStat] = useState('error');

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    setPromoCodes(promocodes)
  }, [promocodes])
  
  const handleRegister = () => {
    axios.post("https://api.youbetshop.com/register", { email: email, pass: password, pass2: confirmPassword,promo_code:promocode2,user_type:2})
      .then(response => {
        if (response) {
          if (response.data.success === true) {
            setMessage("Kayıt Başarılı");
            setOpen(true);
            setStat("success");
            onClose();
          } else {
            setMessage(response.data.message || "Kayıt Başarısız");
            setOpen(true);
            setStat("error");
          }
        } else {
          setMessage("Kayıt Başarısız");
          setOpen(true);
          setStat("error");
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setMessage("Kayıt Başarısız");
        setOpen(true);
        setStat("error");
      });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Kayıt Ol</DialogTitle>
        <DialogContent>
        <Grid container>
            <Grid item xs={6} md={6} sm={6} lg={6}>
        <TextField
            autoFocus
            margin="dense"
            label={"Ad"}
            type="name"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
               </Grid>  <Grid item xs={6} md={6} sm={6} lg={6}>   <TextField
            margin="dense"
            label={"Soyad"}
            type="surname"
            fullWidth
            value={surname}
            onChange={(e) => setSurname(e.target.value)}
          />
          </Grid>
          </Grid>
          <TextField
            margin="dense"
            label="E-posta"
            type="email"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Şifre"
            type={showPassword ? 'text' : 'password'}
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <TextField
            margin="dense"
            label="Şifre Tekrarı"
            type={showConfirmPassword ? 'text' : 'password'}
            fullWidth
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle confirm password visibility"
                    onClick={handleClickShowConfirmPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
            <TextField
            margin="dense"
            label="Promosyon Kodu veya Eposta (Zorunlu)"
            type="promo"
            fullWidth
            value={promocode2}
            onChange={(e) => setPromoCodes(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">İptal</Button>
          <Button onClick={handleRegister} color="primary">Kayıt Ol</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openSnack}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={stat}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default RegisterDialog;
