import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './component/header';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CssBaseline, GlobalStyles,Modal,Box } from '@mui/material';
import CharacterGrid from './component/mainpage';
import Athena from './pages/athena';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import Mybets from './pages/my-bets';
import Ares from './pages/ares';
import Deposit from './pages/deposit';
import Withdraw from './pages/withdraw';
import Mailverify from './pages/mailverify';
import Transactions from './pages/transactions';
import Help from './pages/help';
import ResetPasswordForm from './pages/resetpass';
import WithdrawReq from './pages/withdrawreq';
import DepositReq from './pages/depositreq';
import ComingSoon from './pages/poseidon';
import ComingSoonZeus from './pages/zeus';
import Telegram from './pages/telegram';
import Withdrawapprove from './pages/withdrawapprove';
import Starrace from './pages/star-race';

const theme = createTheme({
  palette: {
    primary: {
      main: '#d79f09', // Replace with your desired primary color
    },
  },
});
const Overlay = ({ open }) => {
  return (
    <Modal
      open={open}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backdropFilter: 'blur(5px)', // Arkaplanı hafif bulanıklaştırır
      }}
    >
      <Box
        sx={{
          position: 'relative',
          bgcolor: 'rgba(0, 0, 0, 0.8)',
          color: 'white',
          width: '80%',
          maxWidth: 400,
          padding: 3,
          borderRadius: 2,
          boxShadow: 24,
          textAlign: 'center',
        }}
      >
        {"children"}
      </Box>
    </Modal>
  );
};
const App = () => {
  const [cookies, setCookie, removeCookie] = useCookies(['serviceToken']);
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Giriş durumu, bu değeri değiştirerek test edebilirsiniz.
  const [username, setUsername] = useState("Zeus Doe");
  const [balance, setBalance] = useState(0); 
  const [newbalance, setNewBalance] = useState(0); 
  const [blockedbalance, setNewBlockedBalance] = useState(0); 
  const [isLoginOpen, setLoginOpen] = useState(false);
  const [blockbalance,setBlockBalance]= useState(0);
  const [verifystatus, setVerifyStatus] = useState(false);
  const [aresfee,setAresFee]= useState(0);
  const [athenafee,setAthenaFee]= useState(0);
  const [poseidonfee,setPoseidonFee]= useState(0);
  const [notify,setNotify]= useState(0);
  const [zeusfee,setZeusFee]= useState(0);
  const [promocodes, setPromocode] = useState('');
  const [telegramverify, setTelegramVerify] = useState('');
  const [mailverify, setMailVerify] = useState('');
  useEffect(() => {
    // Belirli z-index değerine sahip <div> öğelerini Seçenek
    const removeSpecificDiv = () => {
      const divs = document.querySelectorAll('div');
      divs.forEach(div => {
        const style = div.getAttribute('style');
        if (style && style.includes('z-index: 100000;')) {
          div.remove();
        }
      });
    };
 
    // removeSpecificDiv fonksiyonunu 500ms'de bir çalıştır
    const intervalId = setInterval(removeSpecificDiv, 100);
  
    // Bileşen Seçenekıldığında interval'i temizle
    return () => {
      clearInterval(intervalId);
    };
  }, []);
  

  useEffect(() => {
    // URLSearchParams ile URL'deki parametreleri al
    const params = new URLSearchParams(window.location.search);
    const promo = params.get('promocode');

    if (promo) {
      setPromocode(promo);
    }
  }, []);
  const recheck =()=>{
    if (cookies.serviceToken) {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      if (cookies.serviceToken) {
        config.headers['Authorization'] = `Bearer ${cookies.serviceToken}`;
      }

      axios.post("https://api.youbetshop.com/verify", { token: cookies.serviceToken }, config)
        .then(response => {
          if (response) {
            if (response.data.success === true) {
              setIsLoggedIn(true);
              setBalance(response.data.data.balance);
              setNewBalance(response.data.data.balance);
              setAresFee(response.data.data.ares_fee)
              setAthenaFee(response.data.data.athena_fee)
              setPoseidonFee(response.data.data.poseidon_fee)
              setZeusFee(response.data.data.zeus_fee)
              setNewBlockedBalance(response.data.data.block_balance);
              setBlockBalance(response.data.data.block_balance);
              setVerifyStatus(response.data.data.verification_status)
              setTelegramVerify(response.data.data.telegram_verification)
              setMailVerify(response.data.data.email_verification)
              setNotify(response.data.data.ticket_admin_res)
              setUsername(cookies.email);
              setIsLoggedIn(true);
            } else {
              removeCookie("email");
              removeCookie("serviceToken");
            }
          }
        })
        .catch(error => {
          console.error('Error fetching data:', error);
          removeCookie("email");
          removeCookie("serviceToken");
        });
    }else{
      removeCookie("email");
              removeCookie("serviceToken");
    }
  }
  useEffect(() => {
    if (cookies.serviceToken) {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      if (cookies.serviceToken) {
        config.headers['Authorization'] = `Bearer ${cookies.serviceToken}`;
      }

      axios.post("https://api.youbetshop.com/verify", { token: cookies.serviceToken }, config)
        .then(response => {
          if (response) {
            if (response.data.success === true) {
              setIsLoggedIn(true);
              setBalance(response.data.data.balance);
              setBlockBalance(response.data.data.block_balance);
              setNewBlockedBalance(response.data.data.block_balance);
              setNewBalance(response.data.data.balance);
              setVerifyStatus(response.data.data.verification_status);
              setAresFee(response.data.data.ares_fee)
              setAthenaFee(response.data.data.athena_fee)
              setPoseidonFee(response.data.data.poseidon_fee)
              setZeusFee(response.data.data.zeus_fee)
              setTelegramVerify(response.data.data.telegram_verification)
              setMailVerify(response.data.data.email_verification)
              setNotify(response.data.data.ticket_admin_res)
              setUsername(cookies.email);
              setIsLoggedIn(true);
            } else {
              removeCookie("email");
              removeCookie("serviceToken");
            }
          }
        })
        .catch(error => {
          console.error('Error fetching data:', error);
          removeCookie("email");
          removeCookie("serviceToken");
        });
    }else{
      removeCookie("email");
              removeCookie("serviceToken");
    }
  }, [cookies, removeCookie]);
 
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles
        styles={{
          body: {
            backgroundImage: 'url(/image/bg.webp)',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundAttachment: 'fixed',
          },
        }}
      />
      <Router>
        <Header isLoggedIn={isLoggedIn} promocodes={promocodes} notify={notify} setNotify={setNotify}  setTelegramVerify={setTelegramVerify} setMailVerify={setMailVerify} isLoginOpen={isLoginOpen} setLoginOpen={setLoginOpen} balance={balance} setBlockBalance={setBlockBalance} setBalance={setBalance} blockbalance={blockbalance} setIsLoggedIn={setIsLoggedIn} setUsername={setUsername} username={username} />
        <Routes>
          <Route path="/" element={<CharacterGrid setLoginOpen={setLoginOpen} isLoggedIn={isLoggedIn} Overlay={Overlay} mailverify={mailverify} telegramverify={telegramverify} aresfee={aresfee} athenafee={athenafee} poseidonfee={poseidonfee} zeusfee={zeusfee} />} />
          <Route path="/athena" element={<Athena recheck={recheck} newbalance={newbalance} blockedbalance={blockedbalance}  athenafee={athenafee}  balance={balance} setBlockBalance={setBlockBalance} setBalance={setBalance} blockbalance={blockbalance} />} />
          <Route path="/ares" element={<Ares recheck={recheck} newbalance={newbalance} blockedbalance={blockedbalance} aresfee={aresfee}  balance={balance} setBlockBalance={setBlockBalance} setBalance={setBalance} blockbalance={blockbalance} />} />
          <Route path="/my-bets" element={<Mybets />} />
          <Route path="/poseidon" element={<ComingSoon />} />
          <Route path="/zeus" element={<ComingSoonZeus />} />
          <Route path="/verify-mail" element={<Mailverify />} />
          <Route path="/deposit" element={<Deposit recheck={recheck}/>} />
          <Route path="/withdraw" element={<Withdraw recheck={recheck} verifystatus={verifystatus}/>} />
          <Route path="/transactions" element={<Transactions recheck={recheck}/>} />
          <Route path="/help" element={<Help recheck={recheck}/>} />
          <Route path="/reset-password" element={<ResetPasswordForm recheck={recheck}/>} />
          <Route path="/withdrawreq" element={<WithdrawReq recheck={recheck}/>} />
          <Route path="/depositreq" element={<DepositReq recheck={recheck}/>} />
          <Route path="/telegram" element={<Telegram />} />
          <Route path="/star-race" element={<Starrace isLoggedIn={isLoggedIn} />} />
          <Route path="/approve-withdraw" element={<Withdrawapprove isLoggedIn={isLoggedIn} />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;
