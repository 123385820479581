import React, { useEffect, useState } from 'react';
import ares from '../img/ares.png';
import axios from 'axios';
import { Button, Container, Typography, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Grid, AppBar, Toolbar, IconButton, Tooltip, Snackbar, Alert, useTheme } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';

const Ares = ({ recheck, balance, blockbalance, setBalance, setBlockBalance, newbalance, blockedbalance, aresfee }) => {
    const [cookies, setCookie] = useCookies(['serviceToken']);
    const [data, setData] = useState([]);
    const [winValues, setWinvalue] = useState([]); // Boş dizi olarak başlat
    const [nowbalance, setNowBalance] = useState(balance);
    const [nowblockbalance, setNowBlockBalance] = useState(blockbalance);
    const [coupon, setCoupon] = useState([]);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [stat, setStat] = useState(false);
    const [opentool, setOpenTool] = useState(false);
    const [maxwin, setMaxwin] = useState();
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    useEffect(() => {
        setNowBalance(newbalance)
        setNowBlockBalance(blockedbalance)
        setBalance(newbalance)
        setBlockBalance(blockedbalance)
    }, [newbalance, blockedbalance]);

    useEffect(() => {
        const fetchData = () => {
            const accessToken = cookies.serviceToken;
            if (!accessToken) {
                console.error('No access token found');
                return;
            }

            const config = {
                headers: { Authorization: `Bearer ${accessToken}` },
            };

            axios.get("https://api.youbetshop.com/api/coupon/coupon-ares", config)
                .then(response => {
                    setData(response.data.data);
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        };

        fetchData(); // Initially fetch data immediately

        const intervalId = setInterval(fetchData, 5000); // Fetch data every 30 seconds

        return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }, [cookies.serviceToken]);

    const recheckares = () => {
        const accessToken = cookies.serviceToken;
        setWinvalue([]);
        if (!accessToken) {
            console.error('No access token found');
            return;
        }

        const config = {
            headers: { Authorization: `Bearer ${accessToken}` },
        };

        axios.get("https://api.youbetshop.com/api/coupon/coupon-ares", config)
            .then(response => {
                setData(response.data.data);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    const winValue = (value, id) => {
        setWinvalue(prevWinvalue => {
            const existingIndex = prevWinvalue.findIndex(item => item.id === id);

            if (existingIndex !== -1) {
                return prevWinvalue.map(item =>
                    item.id === id ? { ...item, winvalue: value } : item
                );
            } else {
                return [...prevWinvalue, { id, winvalue: value }];
            }
        });
    };

    const addCoup = (matchid, marketid, oddid, price) => {
        setCoupon(prevCoupon => {
            const existingIndex = prevCoupon.findIndex(coup =>
                coup.matchid === matchid &&
                coup.marketid === marketid &&
                coup.oddid === oddid
            );

            if (existingIndex !== -1) {
                return prevCoupon.filter((_, index) => index !== existingIndex);
            }

            const matchIndex = prevCoupon.findIndex(coup => coup.matchid === matchid);

            if (matchIndex !== -1) {
                const newCoupon = [...prevCoupon];
                newCoupon[matchIndex] = { matchid, marketid, oddid, price };
                return newCoupon;
            }

            return [...prevCoupon, { matchid, marketid, oddid, price }];
        });
    };

    const isButtonActive = (oddid, matchid) => {
        return coupon.some(coup => coup.oddid === oddid && coup.matchid === matchid);
    };

    const handleTooltipOpen = () => {
        if (opentool) {
            setOpenTool(false);
        } else {
            setOpenTool(true);
            setTimeout(() => {
                handleTooltipClose();
            }, 5000);
        }
    }

    const handleTooltipClose = () => {
        setOpenTool(false);
    }

    const sendCoupon = (id) => {
        const accessToken = cookies.serviceToken;
        const config = {
            headers: { Authorization: `Bearer ${accessToken}` },
        };
        const par = winValues.find((item) => item.id === id);
        let params = { ares_id: id, stake: par?.winvalue || 0 };
        axios.post("https://api.youbetshop.com/api/coupon/coupon-ares-create", params, config)
            .then(response => {
                if (response.data.data.success) {
                    setMessage("Kuponunuz Oluşturulmuştur");
                    setOpen(true);
                    setStat("success");
                    recheckares();
                } else {
                    setMessage(response.data.data.message);
                    setOpen(true);
                    setStat("error");
                }
                recheck();
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setMessage('Bir hata oluştu');
                setOpen(true);
                setStat("error");
            });
    };

    const dateformat = (dateString) => {
        const originalDate = new Date(dateString);
        originalDate.setHours(originalDate.getHours() - 3);
        const formattedDate = new Intl.DateTimeFormat('tr-TR', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: 'numeric',
            minute: 'numeric',
            hour12: false,
        }).format(originalDate);

        return formattedDate;
    };

    function formatNumber(number) {
        return new Intl.NumberFormat('tr-TR', { style: 'decimal', minimumFractionDigits: 2 }).format(number);
    }

    return (
        <>
            <AppBar position="static">
                <Toolbar>
                    <IconButton edge="start" color="inherit" aria-label="back" onClick={() => {
                        navigate("/");
                        setBalance(formatNumber(nowbalance));
                        setBlockBalance(formatNumber(nowblockbalance));
                    }}>
                        <ArrowBackIcon />
                        <Typography variant="h6">
                            Ana Sayfa
                        </Typography>
                    </IconButton>
                    <Typography variant="h6" style={{ flexGrow: 1 }}>
                    </Typography>
                    <Typography variant="body1" style={{ marginRight: '10px' }}>
                        {"Komisyon: %" + aresfee}
                    </Typography>
                </Toolbar>
            </AppBar>
            <Container sx={{ marginBottom: "30px", marginTop: "50px", backgroundColor: "#ece4d4", paddingLeft: "5px",paddingRight:"5px",paddingBottom:"15px", borderRadius: "15px" }}>
                <Box my={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '15px' }}>
                    <img src={ares} alt="Ares" style={{ width: '150px', height: '150px', borderRadius: '10px' }} />
                    <Box ml={2} textAlign="left">
                        <Typography variant="h4" display="block">
                            Ares
                            <Tooltip
                                title={
                                    <Typography variant="body2">
                                        Ares'ın oynamak istediği kuponu sevmediyseniz Pas butonuna basıp yeni bir kupon yapmasını isteyebilirsiniz.
                                        Her gün 5 tane Pas hakkınız vardır. Eğer Pas haklarınız dolduysa, en az 100TL alarak Ares'nin yeni kupon
                                        yapmasını sağlayabilirsiniz veya kuponun başkaları tarafından alınıp bitmesini beklemeniz gerekir.
                                    </Typography>
                                }
                                arrow
                                PopperProps={{ disablePortal: true }}
                                onClose={handleTooltipClose}
                                open={opentool}
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                            >
                                <IconButton onClick={handleTooltipOpen}>
                                    <HelpOutlineIcon />
                                </IconButton>
                            </Tooltip>
                        </Typography>
                    </Box>
                </Box>

                <TableContainer component={Paper}>
                    <Table>
                        <TableHead sx={{ backgroundColor: "#d5d5d5" }}>
                            <TableRow>
                                <TableCell style={{ padding: isMobile ? "5px" : "16px", minWidth: "150px" }}>Maç Adı</TableCell>
                                <TableCell style={{ padding: isMobile ? "5px" : "16px" }}>Market</TableCell>
                                <TableCell style={{ padding: isMobile ? "5px" : "16px" }}>Seçenek</TableCell>
                                <TableCell style={{ padding: isMobile ? "5px" : "16px" }}>Oran</TableCell>
                                <TableCell style={{ padding: isMobile ? "5px" : "16px" }}>Tarih</TableCell>
                                {!isMobile &&
                                    <>
                                        <TableCell style={{ padding: isMobile ? "5px" : "16px" }}>Max Alınabilir</TableCell>
                                        <TableCell style={{ padding: isMobile ? "5px" : "16px" }}>Blokaj</TableCell>
                                        <TableCell style={{ padding: isMobile ? "5px" : "16px" }}>Tutar</TableCell>
                                        <TableCell style={{ padding: isMobile ? "5px" : "16px" }}></TableCell>
                                    </>
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data?.map((item) => {
                                const eventDetails = JSON.parse(item.description)[0];
                                return (
                                    <React.Fragment key={item.event_name}>
                                        <TableRow>
                                            <TableCell style={{ padding: isMobile ? "5px" : "16px",fontSize:isMobile &&"0.75rem" }}>{eventDetails.event_name}</TableCell>
                                            <TableCell style={{ padding: isMobile ? "5px" : "16px",fontSize:isMobile &&"0.75rem" }}>{eventDetails.market_name}</TableCell>
                                            <TableCell style={{ padding: isMobile ? "5px" : "16px",fontSize:isMobile &&"0.75rem" }}>{eventDetails.odd_name}</TableCell>
                                            <TableCell style={{ padding: isMobile ? "5px" : "16px",fontSize:isMobile &&"0.75rem" }}>{eventDetails.odd}</TableCell>
                                            <TableCell style={{ padding: isMobile ? "5px" : "16px",fontSize:isMobile &&"0.75rem" }}>{dateformat(eventDetails.event_date)}</TableCell>
                                            {!isMobile &&
                                                <>
                                                    <TableCell style={{ padding: isMobile ? "5px" : "16px" }}>                          <Typography onClick={()=>{  winValue(item.total_last_amount, item.id)}} sx={{color:"#d79f09",textDecoration:"underline"}}>{item.total_last_amount}</Typography></TableCell>
                                                    <TableCell style={{ padding: isMobile ? "5px" : "16px" }}>
                                                    {winValues.find(wv => wv.id === item.id)?.winvalue?(winValues.find(wv => wv.id === item.id)?.winvalue*eventDetails.odd).toFixed(2):"0"}
                                                    </TableCell>
                                                    <TableCell style={{ padding: isMobile ? "5px" : "16px",maxWidth:"150px" }} key={item.id + "k"}>  
                                                        <TextField
                                                            type="number"
                                                            value={winValues.find(wv => wv.id === item.id)?.winvalue || ''}
                                                            key={item.id + "v"}
                                                            sx={{
                                                                width: "100%",
                                                                "& .MuiOutlinedInput-notchedOutline": {
                                                                    top: "-5px",
                                                                },
                                                                "& .MuiInputBase-input": {
                                                                    padding: isMobile ? "5px 16px" : "8px 16px",
                                                                },
                                                            }}
                                                            onChange={(e) => {
                                                                const newValue = e.target.value;
                                                                if (item.total_last_amount && newValue > item.total_last_amount) {
                                                                    winValue(item.total_last_amount, item.id);
                                                                    return;
                                                                }
                                                                winValue(newValue, item.id);
                                                            }}
                                                            variant="outlined"
                                                        />
                                                    </TableCell>
                                                    <TableCell style={{ padding: isMobile ? "5px" : "16px" }}>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            sx={{ backgroundColor: "#187700", padding: isMobile ? "5px 16px" : "8px 8px",fontSize:"0.8rem",minWidth:"74px" }}
                                                            onClick={() => sendCoupon(item.id)}
                                                        >
                                                            Kuponu Al
                                                        </Button>
                                                    </TableCell>
                                                </>
                                            }
                                        </TableRow>
                                        <TableRow>
                                            {isMobile &&
                                                <>
                                                    <TableCell colSpan={6} key={item.id + "k"} sx={{ borderBottom: "solid 1px #d79f09", padding: "8px" }}>
                                                        <Grid container sx={{ width: "100%", alignItems: "center" }} spacing={1}>
                                                            <Grid item xs={3} sm={3} >
                                                                <Typography onClick={()=>{  winValue(item.total_last_amount, item.id)}} sx={{color:"#d79f09",textDecoration:"underline"}}><span style={{ fontSize: "0.6rem",color:"#000" ,textDecoration:"none"}}>{"Max Alınabilir:"}</span><br />{item.total_last_amount}</Typography>

                                                            </Grid>
                                                            <Grid item xs={3} sm={3} >
                                                                <Typography><span style={{ fontSize: "0.6rem" }}>{"Bloke Tutarı:"}</span><br />{winValues.find(wv => wv.id === item.id)?.winvalue?(winValues.find(wv => wv.id === item.id)?.winvalue*eventDetails.odd).toFixed(2):"0"}</Typography>
                                                            </Grid>
                                                            <Grid item xs={3} sm={3} >
                                                                <TextField
                                                                    type="number"
                                                                    key={item.id + "v"}
                                                                    value={winValues.find(wv => wv.id === item.id)?.winvalue || ''}
                                                                    sx={{
                                                                        width: "100%",
                                                                        "& .MuiOutlinedInput-notchedOutline": {
                                                                            top: "-5px",
                                                                        },
                                                                        "& .MuiInputBase-input": {
                                                                            padding: isMobile ? "5px 5px" : "8px 5px",
                                                                        },
                                                                    }}
                                                                    onChange={(e) => {
                                                                        const newValue = e.target.value;
                                                                        if (item.total_last_amount && newValue > item.total_last_amount) {
                                                                            winValue(item.total_last_amount, item.id);
                                                                            return;
                                                                        }
                                                                        winValue(newValue, item.id);
                                                                    }}
                                                                    variant="outlined"
                                                                />
                                                            </Grid>
                                                            <Grid item xs={3} sm={3} >
                                                                <Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    sx={{ backgroundColor: "#187700", padding: isMobile ? "5px 6px" : "8px 6px", fontSize: "0.7rem" }}
                                                                    onClick={() => sendCoupon(item.id)}
                                                                >
                                                                    Kuponu Al
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </TableCell>
                                                </>
                                            }
                                        </TableRow>
                                    </React.Fragment>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Container>

            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    severity={stat}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {message}
                    <Button sx={{ color: "#ece4d4", textDecoration: "underline" }} onClick={() => navigate("/my-bets")}>
                        Bahislerim
                    </Button>
                </Alert>
            </Snackbar>
        </>
    );
};

export default Ares;
