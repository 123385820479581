import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import AccountBalanceWallet from '@mui/icons-material/AccountBalanceWallet';
import SafetyCheckIcon from '@mui/icons-material/SafetyCheck';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { Link } from 'react-router-dom';
import AttachMoney from '@mui/icons-material/AttachMoney';
import Person from '@mui/icons-material/Person';
import Support from '@mui/icons-material/Support';
import Login from '@mui/icons-material/Login';
import PersonAdd from '@mui/icons-material/PersonAdd';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme, Box,Grid,Badge} from '@mui/material';
import { useCookies } from 'react-cookie';
import moment from 'moment';
import LoginDialog from '../dialogs/LoginDialog';
import RegisterDialog from '../dialogs/RegisterDialog';
import TelegramIcon from '@mui/icons-material/Telegram';
import ForumIcon from '@mui/icons-material/Forum';
import { useNavigate } from 'react-router-dom';
import StarIcon from '@mui/icons-material/Star';
import axios from 'axios';
const Header = ({ isLoggedIn, username,setIsLoggedIn,setUsername,balance,setBalance,isLoginOpen, setLoginOpen,blockbalance,promocodes,setMailVerify,setTelegramVerify,setNotify,notify}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isRegisterOpen, setRegisterOpen] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(['serviceToken']);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const currentDate = moment().format('YYYY-MM-DD HH:mm'); // 'LLL' formatını kullanarak güncel tarih ve saat
  const navigate=useNavigate();
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLoginOpen = () => {
    setLoginOpen(true);
    handleClose();
  };

  const handleLoginClose = () => {
    setLoginOpen(false);
  };

  const handleRegisterOpen = () => {
    setRegisterOpen(true);
    handleClose();
  };
  const formatCurrency = (value) => {
    return value?.toLocaleString('tr-TR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };
  const handleRegisterClose = () => {
    setRegisterOpen(false);
  };
  const logout = ()=>{
    setIsLoggedIn(false);
    removeCookie("email");
    removeCookie("serviceToken");
  }
  const redirectforum=()=>{
    const token = cookies.serviceToken;
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };
    const newWindow = window.open('', '_blank');

    axios.get("https://api.youbetshop.com/api/user/forum", config)
        .then(response => {
           const ftoken=response.data.token
           if(ftoken){
            newWindow.location.href ="https://forumyoubetshop.com/dtoken-login?dtoken="+ftoken;
           }

        }).catch(error => {
            console.error('Error with API requests:', error);
        });
  }
  return (
    <AppBar position="static" sx={{ backgroundColor: '#333', padding: 1 }}>
      <Toolbar sx={{paddingRight:"0px",justifyContent:"space-between"}}>
        {/* <Typography variant="h6" sx={{ flexGrow: isLoggedIn?1.5:1 }}>
          MyApp
        </Typography> */}
        <img src={"image/logo.png"} onClick={()=>navigate("/")} style={{maxWidth:isMobile?"120px":"200px",flexGrow: isLoggedIn?1.5:1 }}/>
        {isMobile ? (
          <>                <Grid container sx={{ justifyContent: "end",maxWidth:isLoggedIn?"220px":"150px" }}>
            {isLoggedIn?
            <>
                      <Grid item xs={4} sm={4} md={4} lg={4}>
         
         <Button
         component={Link} to="/deposit"
           variant="contained"
           size="small"
           sx={{
             display: 'flex',
             flexDirection: 'column',
             justifyContent: 'center',
             backgroundColor: "#cd7474"
           }}
         >
           <Box>
             <SafetyCheckIcon sx={{ fontSize: 24 }} />
           </Box>
           <Box>
             <Typography sx={{ fontSize: 11 }}>{formatCurrency(blockbalance)}</Typography>
           </Box>
         </Button>
       </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4}>
         
            <Button
            component={Link} to="/deposit"
              variant="contained"
              size="small"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                backgroundColor: "#125f09"
              }}
            >
              <Box>
                <AccountBalanceWalletIcon sx={{ fontSize: 24 }} />
              </Box>
              <Box>
                <Typography sx={{ fontSize: 11 }}>{formatCurrency(balance)}</Typography>
              </Box>
            </Button>
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4} >
            <Button
              variant="contained"
              size="small"
              onClick={handleMenuOpen}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <Box>
                <AccountCircleIcon sx={{ fontSize: 24 }} />
              </Box>
              <Box>
                <Typography sx={{ fontSize: 11 }}>{"Hesabım"}</Typography>
              </Box>
            </Button>
          </Grid>
          </> 
          :
          <>
          <Grid item xs={6} sm={6} md={6} lg={6}>
         
            <Button
            onClick={handleLoginOpen} 
              variant="contained"
              size="small"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                backgroundColor: "#125f09"
              }}
            >
              <Box>
                <AccountBalanceWalletIcon sx={{ fontSize: 24 }} />
              </Box>
              <Box>
                <Typography sx={{ fontSize: 11 }}>{"Giriş Yap"}</Typography>
              </Box>
            </Button>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} >
            <Button
              variant="contained"
              size="small"
              onClick={handleRegisterOpen}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <Box>
                <AccountCircleIcon sx={{ fontSize: 24 }} />
              </Box>
              <Box>
                <Typography sx={{ fontSize: 11 }}>{"Kayıt Ol"}</Typography>
              </Box>
            </Button>
          </Grid>
          </> 
          }
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
              {isLoggedIn ? (
                <>
                  <MenuItem onClick={handleClose}>
                    <Person fontSize="small" /> Kullanıcı Adı: {username}
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <AccountBalanceWallet fontSize="small" /> Kullanılabilir Limit: {balance} TRY
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <AttachMoney fontSize="small" /> Blokeli Limit: {blockbalance} TRY
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    {currentDate}
                  </MenuItem>
                  <MenuItem onClick={()=>{handleClose(),navigate("/deposit")}}>
                    <AttachMoney fontSize="small" /> Para Yatır
                  </MenuItem>
                  <MenuItem onClick={()=>{handleClose(),navigate("/withdraw")}}>
                    <AttachMoney fontSize="small" /> Para Çek
                  </MenuItem>
                  <MenuItem onClick={()=>{handleClose(),navigate("/transactions")}}>
                    <Person fontSize="small" /> Kasa
                  </MenuItem>
                  <MenuItem onClick={()=>{handleClose(),navigate("/my-bets")}}>
                    <Person fontSize="small" /> Hesabım
                  </MenuItem>
                  <MenuItem onClick={()=>{handleClose(),navigate("/star-race")}}>
                    <StarIcon fontSize="small" /> Yıldız Yarışı
                  </MenuItem>
                  <MenuItem onClick={() => { handleClose(); navigate("/help"); }}>
  <Badge badgeContent={notify} color="error">
    <Support fontSize="small" />
  </Badge>
  &nbsp; Destek
</MenuItem>
                  <MenuItem onClick={()=>{handleClose(),navigate("/telegram")}}>
                    <TelegramIcon fontSize="small" /> Telegram
                  </MenuItem>
                  <MenuItem onClick={logout}>
                    <Support fontSize="small" /> Çıkış Yap
                  </MenuItem>
                </>
              ) : (
                <>
                  <MenuItem onClick={handleLoginOpen}>
                    <Login fontSize="small" /> Giriş Yap
                  </MenuItem>
                  <MenuItem onClick={handleRegisterOpen}>
                    <PersonAdd fontSize="small" /> Kayıt Ol
                  </MenuItem>
                </>
              )}
          </Menu>
        </Grid>

          </>
        ) : (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            {isLoggedIn ? (
              <>
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <Button color="inherit" sx={{ color: '#ece4d4' }}  onClick={()=>navigate("/transactions")} startIcon={<AccountBalanceWallet />}>Kasa</Button>
                  <Button color="inherit" sx={{ color: '#ece4d4' }} onClick={()=>navigate("/deposit")} startIcon={<AttachMoney />}>Para Yatır</Button>
                  <Button color="inherit" sx={{ color: '#ece4d4' }} onClick={()=>navigate("/withdraw")} startIcon={<AttachMoney />}>Para Çek</Button>
                  <Button color="inherit" sx={{ color: '#ece4d4' }} onClick={()=>navigate("/my-bets")} startIcon={<Person />}>Hesabım</Button>
                  <Button color="inherit" sx={{ color: '#ece4d4' }} onClick={()=>navigate("/star-race")} startIcon={<StarIcon />}>Yıldız Yarışı</Button>
                  <Badge badgeContent={notify} color="error">
        <Button color="inherit" sx={{ color: '#ece4d4' }} onClick={() => navigate("/help")} startIcon={<Support />}>Destek</Button>
      </Badge>
                  <Button color="inherit" sx={{ color: '#ece4d4' }} onClick={()=>navigate("/Telegram")} startIcon={<TelegramIcon />}>Telegram</Button>
                  <Button color="inherit" sx={{ color: '#ece4d4' }} onClick={()=>logout()} startIcon={<Support />}>Çıkış Yap</Button>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                  <Grid container>
                    <Grid item xs={4} sm={4} md={4} lg={4}>

                      <Button
                        component={Link} to="/deposit"
                        variant="contained"
                        size="small"
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          backgroundColor: "#cd7474"
                        }}
                      >
                        <Box>
                          <SafetyCheckIcon sx={{ fontSize: 24 }} />
                        </Box>
                        <Box>
                          <Typography sx={{ fontSize: 11 }}>{formatCurrency(blockbalance)}</Typography>
                        </Box>
                      </Button>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4}>

                      <Button
                        component={Link} to="/deposit"
                        variant="contained"
                        size="small"
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          backgroundColor: "#125f09"
                        }}
                      >
                        <Box>
                          <AccountBalanceWalletIcon sx={{ fontSize: 24 }} />
                        </Box>
                        <Box>
                          <Typography sx={{ fontSize: 11 }}>{formatCurrency(balance)}</Typography>
                        </Box>
                      </Button>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4} >
                      <Button
                        variant="contained"
                        size="small"
                        onClick={handleMenuOpen}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                        }}
                      >
                        <Box>
                          <AccountCircleIcon sx={{ fontSize: 24 }} />
                        </Box>
                        <Box>
                          <Typography sx={{ fontSize: 11 }}>{"Hesabım"}</Typography>
                        </Box>
                      </Button>
                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                      >
                  <MenuItem onClick={handleClose}>
                    <Person fontSize="small" /> Kullanıcı Adı: {username}
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <AccountBalanceWallet fontSize="small" /> Kullanılabilir Limit: {balance} TRY
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <AttachMoney fontSize="small" /> Blokeli Limit: {blockbalance} TRY
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    {currentDate}
                  </MenuItem>
                  <MenuItem onClick={()=>{handleClose(),navigate("/deposit")}}>
                    <AttachMoney fontSize="small" /> Para Yatır
                  </MenuItem>
                  <MenuItem onClick={()=>{handleClose(),navigate("/withdraw")}}>
                    <AttachMoney fontSize="small" /> Para Çek
                  </MenuItem>
                  <MenuItem onClick={()=>{handleClose(),navigate("/transactions")}}>
                    <Person fontSize="small" /> Kasa
                  </MenuItem>
                  <MenuItem onClick={()=>{handleClose(),navigate("/my-bets")}}>
                    <Person fontSize="small" /> Hesabım
                  </MenuItem>
                  <MenuItem onClick={()=>{handleClose(),navigate("/star-race")}}>
                    <StarIcon fontSize="small" /> Yıldız Yarışı
                  </MenuItem>
                  <MenuItem onClick={() => { handleClose(); navigate("/help"); }}>
  <Badge badgeContent={notify} color="error">
    <Support fontSize="small" />
  </Badge>
  &nbsp; Destek
</MenuItem>
                  <MenuItem onClick={()=>{handleClose(),navigate("/telegram")}}>
                    <TelegramIcon fontSize="small" /> Telegram
                  </MenuItem>
                  <MenuItem onClick={logout}>
                    <Support fontSize="small" /> Çıkış Yap
                  </MenuItem>


                      </Menu>
                    </Grid>
                  </Grid>
                  {/* <Typography variant="body1" sx={{ color: '#ece4d4' }}>
                    <strong style={{ color: '#ece4d4' }}>{translatedata[selectedLanguage].header.username}</strong> {username}
                  </Typography>
                  <Typography variant="body1" sx={{ color: '#4caf50' }}>
                    <strong style={{ color: '#ece4d4' }}>{translatedata[selectedLanguage].header.availablelimit}</strong> {balance} {translatedata[selectedLanguage].header.currency}
                  </Typography>
                  <Typography variant="body1" sx={{ color: '#ece4d4' }}>
                    <strong>{translatedata[selectedLanguage].header.blocklimit}</strong> {blockbalance} {translatedata[selectedLanguage].header.currency}
                  </Typography> */}
                </Box>
              </>
            ) : (
              <Box sx={{ display: 'flex', gap: 2 }}>
                <Button color="inherit" sx={{ color: '#d79f09' }} startIcon={<Login />} onClick={handleLoginOpen}>Giriş Yap</Button>
                <Button color="inherit" sx={{ color: '#d79f09' }} startIcon={<PersonAdd />} onClick={handleRegisterOpen}>Kayıt Ol</Button>
              </Box>
            )}
          </Box>
        )}
      </Toolbar>
      <LoginDialog  setBalance={setBalance}  open={isLoginOpen} setNotify={setNotify} setMailVerify={setMailVerify} setTelegramVerify={setTelegramVerify} setUsername={setUsername} setIsLoggedIn={setIsLoggedIn} onClose={handleLoginClose} />
      <RegisterDialog open={isRegisterOpen} onClose={handleRegisterClose} promocodes={promocodes} />
    </AppBar>
  );
};

export default Header;
