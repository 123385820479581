import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {AppBar,Toolbar,IconButton} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
const ComingSoon = () => {
    const navigate = useNavigate();
  return (
    <>
    <AppBar position="static">
    <Toolbar>
        <IconButton edge="start" color="inherit"  aria-label="back" onClick={()=>navigate("/")}>
            <ArrowBackIcon />
            <Typography variant="h6" >
            Ana Sayfa
        </Typography>
        </IconButton>
        <Typography variant="h6" style={{ flexGrow: 1}}>
           
        </Typography>
        <Typography variant="body1" style={{ marginRight: '10px' }}>
        {"Zeus"}
        </Typography>
    </Toolbar>
</AppBar>
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      bgcolor="#f5f5f5"
    >
      <Typography variant="h4" component="h1">
        Pek Yakında Sizlerle
      </Typography>
    </Box>
    </>
  );
};

export default ComingSoon;
