import React, { useState,useEffect } from 'react';
import { TextField, Button, Snackbar, Alert, Container, AppBar, Toolbar, IconButton, Typography, Grid, useTheme, InputAdornment, Paper, Box,FormControlLabel,Switch} from '@mui/material';
import axios from 'axios';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import FileCopyIcon from '@mui/icons-material/FileCopy';
const Telegram = () => {
    const [cookies, setCookie] = useCookies(['serviceToken']);
  const [telegramusername, setTelegramUsername] = useState();
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [message, setMessage] = useState('');
  const [stat, setStat] = useState('info'); // info, success, error
  const [openSnack, setOpenSnack] = useState(false);
  const query = new URLSearchParams(location.search);
  const token = query.get('token');
  const theme = useTheme();
  const [activeTel,setActiveTel]=useState();
  const [uniqcode,setUniqcode]=useState();
  const[verifyId,setVerifyId]=useState(0);
  const [telconf,setTelConf]=useState();
  const [telrule,setTelRule]=useState();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  };

useEffect(() => {
    const accessToken = cookies.serviceToken;
    const config = {
        headers: { Authorization: `Bearer ${accessToken}` },
    };

    axios.get("https://api.youbetshop.com/api/telegram/active-telegram ",  config)
        .then(response => {
            if(response.data.success==true){
                setTelConf(response.data.data.notification_rule)
                setActiveTel("true")
            }else{
                setActiveTel("false")
            }
        })
        .catch(error => {
            console.error('Error fetching data:', error);
            setMessage('Bir hata oluştu');
            setOpenSnack(true);
            setStat("error");
        });
   
}, [])

const getcode=()=>{
    const accessToken = cookies.serviceToken;
    const config = {
        headers: { Authorization: `Bearer ${accessToken}` },
    };

        axios.post("https://api.youbetshop.com/api/telegram/create-telegram",{"tusername": telegramusername}, config)
        .then(response => {
            setUniqcode(response.data.data.uniq_code)
            setActiveTel("wait")
            setVerifyId(response.data.data.id)
        })
        .catch(error => {
            console.error('Error fetching data:', error);
            setMessage('Bir hata oluştu');
            setOpenSnack(true);
            setStat("error");
        });
 
}
useEffect(() => {

        const fetchData = async () => {
            const accessToken = cookies.serviceToken;
            const config = {
                headers: { Authorization: `Bearer ${accessToken}` },
            };
          try {
            const response = await axios.post('https://api.youbetshop.com/api/telegram/status-telegram', { id: verifyId },config);
    
           if(response.data.data.status==1){
            setActiveTel("true")
            setTelRule(response.data.data.notification_rule)
            setVerifyId(0)
           }else if(response.data.data.status==0){
            setActiveTel("wait")
           }else{
            setActiveTel("false")
            setVerifyId(0)
           }
    
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
        const interval = setInterval(() => {
            if(!verifyId==0){
            fetchData();
            }
          }, 10000);
          return () => clearInterval(interval); 

  }, [uniqcode]);
const copyToClipboard = () => {
    navigator.clipboard.writeText(uniqcode);
    setMessage('Kod Kopyalandı.');
    setOpenSnack(true);
    setStat("success");
  };

  const handleInputChange = (id, key, value) => {
    const updatedRules = telconf.map((rule) =>
      rule.id === id ? { ...rule, [key]: value } : rule
    );
    setTelConf(updatedRules);
  };

  const getRuleById = (id) => telconf.find(rule => rule.id === id);
  const handleSave = () => {
    const accessToken = cookies.serviceToken;
    const config = {
        headers: { Authorization: `Bearer ${accessToken}` },
    };
    axios.post("https://api.youbetshop.com/api/telegram/update-telegram",{"ruleArr": telconf}, config)
    .then(response => {
           if(response.data.success==true){
            setMessage('Ayarlarınız Başarıyla Kaydedildi');
            setOpenSnack(true);
            setStat("success");
           }else{
            setMessage('Bir hata oluştu');
            setOpenSnack(true);
            setStat("error");
           }
    })
    .catch(error => {
        console.error('Error fetching data:', error);
        setMessage('Bir hata oluştu');
        setOpenSnack(true);
        setStat("error");
    });
    
  };
  const deletetel = () => {
    const accessToken = cookies.serviceToken;
    const config = {
        headers: { Authorization: `Bearer ${accessToken}` },
    };
    axios.get("https://api.youbetshop.com/api/telegram/deactive-telegram", config)
    .then(response => {
            if(response.data.success=true){
                setMessage('Telegram Başarıyla Kaldırıldı');
                setOpenSnack(true);
                setStat("success");
                setActiveTel("false")
            }else{
                setMessage('Bir hata oluştu');
                setOpenSnack(true);
                setStat("error"); 
            }
    })
    .catch(error => {
        console.error('Error fetching data:', error);
        setMessage('Bir hata oluştu');
        setOpenSnack(true);
        setStat("error");
    });
    
  };
  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="back" onClick={() => navigate("/")}>
            <ArrowBackIcon />
            <Typography variant="h6">
              Ana Sayfa
            </Typography>
          </IconButton>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
          </Typography>
          <Typography variant="body1" style={{ marginRight: '10px' }}>
            {"Telegram"}
          </Typography>
        </Toolbar>
      </AppBar>
      <Container
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '60vh'
        }}
      >
        <Paper elevation={3} sx={{ padding: 2, maxWidth: 400, width: '100%',marginTop:"20px" }}>
          <Box textAlign="center" marginBottom={2}>
            <Typography variant="h5" sx={{ color: "#333" }}>
              Telegram Entegrasyonu </Typography>
          </Box>
          <Grid container spacing={2}>
            {activeTel=="true"?
                       <Box sx={{ p: 1 }}>
                       <Box sx={{ display: 'flex', alignItems: 'center' }}>
                         <Typography variant="h6" sx={{ flexGrow: 1,fontSize:"1rem" }}>Kupon Bildirimleri</Typography>

                         <FormControlLabel
                           control={
                             <Switch
                               checked={!!getRuleById('coupon_rule').is_active}
                               onChange={(e) => handleInputChange('coupon_rule', 'is_active', e.target.checked ? 1 : 0)}
                             />
                           }
                           label={getRuleById('coupon_rule').is_active==1?"Aktif":"Pasif"}
                         />
                       </Box>
                       <Typography sx={{ flexGrow: 1,marginBottom:"5px" }}>Kupon bildirimleri hangi kupon tutarı aralığında gelsin?</Typography>
                       <Box sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
                       <TextField
                           label="Min"
                           type="number"
                           value={getRuleById('coupon_rule').min}
                           onChange={(e) => handleInputChange('coupon_rule', 'min', e.target.value)}
                           sx={{ mr: 2 }}
                         />
                         <TextField
                           label="Max"
                           type="number"
                           value={getRuleById('coupon_rule').max}
                           onChange={(e) => handleInputChange('coupon_rule', 'max', e.target.value)}
                           sx={{ mr: 2 }}
                         />
                         </Box>
                       <Box sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
                         <Typography variant="h6" sx={{ flexGrow: 1,fontSize:"1rem" }}>Yatırım Bildirimleri</Typography>
                         <FormControlLabel
                           control={
                             <Switch
                               checked={!!getRuleById('deposit_rule').is_active}
                               onChange={(e) => handleInputChange('deposit_rule', 'is_active', e.target.checked ? 1 : 0)}
                             />
                           }
                           label={getRuleById('deposit_rule').is_active==1?"Aktif":"Pasif"}
                         />
                       </Box>
 
                       <Box sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
                         <Typography variant="h6" sx={{ flexGrow: 1,fontSize:"1rem" }}>Çekim Bildirimleri</Typography>
                         <FormControlLabel
                           control={
                             <Switch
                               checked={!!getRuleById('withdraw_rule').is_active}
                               onChange={(e) => handleInputChange('withdraw_rule', 'is_active', e.target.checked ? 1 : 0)}
                             />
                           }
                           label={getRuleById('withdraw_rule').is_active==1?"Aktif":"Pasif"}
                         />
                       </Box>
                 
                       <Box sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
                         <Typography variant="h6" sx={{ flexGrow: 1,fontSize:"1rem" }}>Giriş Bildirimleri</Typography>
                         <FormControlLabel
                           control={
                             <Switch
                               checked={!!getRuleById('login_rule').is_active}
                               onChange={(e) => handleInputChange('login_rule', 'is_active', e.target.checked ? 1 : 0)}
                             />
                           }
                           label={getRuleById('login_rule').is_active==1?"Aktif":"Pasif"}
                         />
                       </Box>
                 
                       <Box sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
                         <Typography variant="h6" sx={{ flexGrow: 1,fontSize:"1rem" }}>Çıkış Bildirimleri</Typography>
                         <FormControlLabel
                           control={
                             <Switch
                               checked={!!getRuleById('logout_rule').is_active}
                               onChange={(e) => handleInputChange('logout_rule', 'is_active', e.target.checked ? 1 : 0)}
                             />
                           }
                           label={getRuleById('logout_rule').is_active==1?"Aktif":"Pasif"}
                         />
                       </Box>
                 
                       <Box sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
                         <Typography variant="h6" sx={{ flexGrow: 1,fontSize:"1rem" }}>Kupon Kazanma Bildirimleri</Typography>
                         <FormControlLabel
                           control={
                             <Switch
                               checked={!!getRuleById('win_rule').is_active}
                               onChange={(e) => handleInputChange('win_rule', 'is_active', e.target.checked ? 1 : 0)}
                             />
                           }
                           label={getRuleById('win_rule').is_active==1?"Aktif":"Pasif"}
                         />
                       </Box>
                       <Button variant="contained" sx={{backgroundColor:"#a30035",marginRight:"5px"}} onClick={deletetel}>
                         Kaldır
                       </Button>
                       <Button variant="contained" onClick={handleSave}>
                         Ayarları Kaydet
                       </Button>
                     </Box>
            :
            activeTel=="wait"
            ?
            <Grid container direction="column" alignItems="center">
            <Typography sx={{ margin:"10px" }}>
              Aşağıdaki kodu <a href={"https://t.me/YouBetShopBot"} target="_blank">{"https://t.me/YouBetShopBot"}</a> adresine gönderin. Bottan size "Aktifleştirilmiştir" mesajı gelene kadar bekleyin.
            </Typography>
            <Typography sx={{ marginTop: "10px", textAlign: "center", color: "#d79f09" }} variant="h5">
              {uniqcode}<Button onClick={copyToClipboard} sx={{ mt: 1 }} startIcon={<FileCopyIcon />}/>
            </Typography>
            
  
           
          </Grid>
            :
      
        <>
        <Grid item xs={12}>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          onClick={()=>getcode()}
          fullWidth
        >
          Telegram Kodu Al
        </Button>
      </Grid>
      </>
        
        }

          </Grid>
        </Paper>
        <Snackbar
          open={openSnack}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={stat}
            sx={{ width: '100%' }}
          >
            {message}
          </Alert>
        </Snackbar>
      </Container>
    </>
  );
};

export default Telegram;
