import React,{useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Card, CardContent, Typography, Grid, Avatar,Button,Snackbar,Alert,CircularProgress } from '@mui/material';
import axios from 'axios';
import ares from '../img/ares.png';
import zeus from '../img/zeus.png';
import athena from '../img/athena.png';
import poseidon from '../img/poseidon.png';
import { useCookies } from 'react-cookie';
const characters = [
  {
    name: 'Ares',
    description: 'Ares bahis ve istatistik uzmanı, onun belirlediği kuponları herzaman üstlenebilirsiniz. Eğer kaybederse Discount istemez.',
    discount: '%0',
    img: ares
  },
  {
    name: 'Zeus',
    description: 'Zeus Las Vegas Şehri’nin en ünlü kumarhanesi olan Golden Nugget ile özel bir anlaşma yaparak kendi slot makinelerini çalıştırmaya başladı, John’un bu kazancına ortak olmak isterseniz makine satın alabilirsiniz',
    discount: '%20',
    img: zeus
  }
];

const CharacterCard = ({ character, setLoginOpen, isLoggedIn, aresfee, athenafee, poseidonfee, zeusfee }) => {
  const [hovered, setHovered] = React.useState(false);
  const navigate = useNavigate();

  const handleClick = () => {
    if (isLoggedIn == false) {
      setLoginOpen(true)
    } else {
      navigate(`/${character.name}`);
    }

  };

  return (
    <Card
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        backgroundColor: "#1a1a1c",
        flexDirection: 'column',
        position: 'relative',
        '&:hover': {
          transform: 'scale(1.05)',
          zIndex: 1,
        }
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={handleClick}
    >
      {isLoggedIn &&
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            backgroundColor: "#1a1a1c",
            color: '#ece4d4',
            padding: '4px 4px',
            borderTopRightRadius: '4px',
            borderBottomLeftRadius: '4px',
            zIndex: 2
          }}
        >
          {character.name == "Ares" && aresfee + "%"}
          {character.name == "Athena" && athenafee + "%"}
          {character.name == "Zeus" && zeusfee + "%"}
          {character.name == "Poseidon" && poseidonfee + "%"}
        </Box>}

      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          backgroundColor: "#1a1a1c",
          color: '#ece4d4',
          padding: '4px 4px',
          borderTopLeftRadius: '4px',
          borderBottomRightRadius: '4px',
          backgroundColor: "#d79f09"
        }}
      >
        {character.name}
      </Box>
      <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <Avatar
          alt={character.name}
          src={character.img}
          sx={{ width: 200, height: 200, marginBottom: 2 }}
        />
        <Typography variant="h6" align="center" gutterBottom sx={{ color: "#ece4d4" }}>
          {character.name}
        </Typography>
        <Typography variant="body2" align="center" color="textSecondary" sx={{ color: "#ece4d4" }}>
          {character.description}
        </Typography>
      </CardContent>
    </Card>
  );
};

const CharacterGrid = ({ setLoginOpen, isLoggedIn, aresfee, athenafee, poseidonfee, zeusfee, mailverify, telegramverify }) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState(false);
  const [stat, setStat] = useState(false);
  const [cookies, setCookie] = useCookies(['serviceToken']);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
        return;
    }
    setOpen(false);
};
  const sendmail = () => {
    setLoading(true);
    const accessToken = cookies.serviceToken;
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` },
    };
    axios.post("https://api.youbetshop.com/api/user/verifymail", "", config)
      .then(response => {
        if (response.data.success=true) {
          setLoading(false);
          setMessage("Mail Gönderildi.");
          setOpen(true);
          setStat("success");
        } else {
          setLoading(false);
          setMessage(response.data.message);
          setOpen(true);
          setStat("error");
        }
      })
      .catch(error => {
        console.error('Error creating coupon:', error);
        setMessage(error);
        setOpen(true);
        setStat("error");
      });
  }
  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: "5rem" }}>
        <Grid container sx={{ maxHeight: "120rem", maxWidth: "60rem", backgroundColor: "#ece4d4", borderRadius: "1rem" }}>
          {!mailverify && isLoggedIn ?
            <Grid item xs={12} md={12} sx={{ padding: "50px" }}>
              <Typography variant={"h4"}>{"Mail Doğrulama"}</Typography>
              <Typography>{"Lütfem mail adresinize gelen doğrulama e-postasını onaylayın."}</Typography>
              <Box justifyContent={"flex-end"} sx={{display:"flex",marginBottom:"20px"}}>
              <Button onClick={sendmail} variant="outlined" disabled={loading}>
            {loading ? <CircularProgress size={24} /> : 'Tekrar Eposta Gönder'}
          </Button>
          </Box>
          <Typography variant={"h4"}>{"Telegram Doğrulama"}</Typography>
              <Typography>{"Lütfem telegram sayfasından,telegram hesabınızı doğrulayın."}</Typography>
              <Box justifyContent={"flex-end"} sx={{display:"flex"}}>
              <Button onClick={()=>navigate(`/telegram`)} variant="outlined" disabled={loading}>
            {'Doğrulama Sayfası'}
          </Button>
          </Box>
            </Grid>
            :
            characters.map((character, index) => (
              <Grid item xs={12} md={6} sx={{ padding: "16px" }} key={index} >
                <CharacterCard character={character} setLoginOpen={setLoginOpen} isLoggedIn={isLoggedIn} aresfee={aresfee} athenafee={athenafee} poseidonfee={poseidonfee} zeusfee={zeusfee} />
              </Grid>
            ))
          }
        </Grid>
      </Box>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={stat}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {message}

        </Alert>
      </Snackbar>
    </>
  );
};

export default CharacterGrid;
