import React, { useEffect, useState,useRef } from 'react';
import athena from '../img/athena.png';
import axios from 'axios';
import { Button, Container, Typography, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Grid, AppBar, Toolbar, IconButton, Link,Badge, Alert, useTheme, Tooltip, Popover, List, ListItem, ListItemText, Fab } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import InvoiceIcon from '@mui/icons-material/Receipt'; // Import the invoice icon
import useMediaQuery from '@mui/material/useMediaQuery';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDateTimeRangePickerDefaultizedProps } from '@mui/x-date-pickers-pro/DateTimeRangePicker/shared';

const Athena = ({ recheck, balance, blockbalance, setBalance, setBlockBalance, newbalance, blockedbalance, athenafee }) => {
    const [cookies, setCookie] = useCookies(['serviceToken']);
    const [data, setData] = useState([]);
    const [fdata, setFData] = useState([]);
    const [totalPrice, setTotalPrice] = useState();
    const [nowbalance, setNowBalance] = useState(balance);
    const [nowblockbalance, setNowBlockBalance] = useState(blockbalance);
    const [winvalue, setWinvalue] = useState();
    const [coupon, setCoupon] = useState([]);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState(false);
    const [stat, setStat] = useState(false);
    const [opentool, setOpenTool] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null); // State for Popover
    const theme = useTheme();
    const popoverRef = useRef(null);
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const navigate = useNavigate();
    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    useEffect(() => {
        setNowBalance(newbalance);
        setNowBlockBalance(blockedbalance);
        setBalance(newbalance);
        setBlockBalance(blockedbalance);
    }, [newbalance, blockedbalance]);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popoverRef.current && !popoverRef.current.contains(event.target)) {
                handlePopoverClose();
            }
        };
    
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [popoverRef]);
    useEffect(() => {
        const accessToken = cookies.serviceToken;
        const config = {
            headers: { Authorization: `Bearer ${accessToken}` },
        };
        axios.get("https://api.youbetshop.com/api/feed/athena-feed", config)
            .then(response => {
                const events = response.data.data.map(event => ({
                    ...event,
                    openDate: new Date(event.openDate)
                }));
                events.sort((a, b) => a.openDate - b.openDate);
                setFData(events);
                setData(events);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, [cookies.serviceToken]);

    const dateformat = (dateString) => {
        const originalDate = new Date(dateString);
        originalDate.setHours(originalDate.getHours() - 3);
        return new Intl.DateTimeFormat('tr-TR', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: 'numeric',
            minute: 'numeric',
            hour12: false,
        }).format(originalDate);
    };
    useEffect(() => {
        if (coupon.length > 0) {
          const parseDatetime = (dtStr) => new Date(dtStr);
    
          const timeRange = 2 * 60 * 60 * 1000; // 2 hours in milliseconds
    
          const filtered = fdata.filter((detailedMatch) => {
            const detailedMatchTime = parseDatetime(detailedMatch.openDate).getTime();
            return coupon.every((match) => {
              const matchTime = parseDatetime(match.datetime).getTime();
              const startTime = matchTime - timeRange;
              const endTime = matchTime + timeRange;
    
              if (detailedMatch.id === match.matchid) {
                return true;
              } else {
                return !(startTime < detailedMatchTime && detailedMatchTime < endTime) && matchTime !== detailedMatchTime;
              }
            });
          });
          setFilteredData(filtered);
        } else {
          setFilteredData(fdata);
        }
      }, [coupon, fdata]);

    const addCoup = (matchid, matchname,datetime, marketid, marketname, oddid, oddname, price) => {
        setCoupon(prevCoupon => {
            const existingIndex = prevCoupon.findIndex(coup =>
                coup.matchid === matchid &&
                coup.marketid === marketid &&
                coup.oddid === oddid
            );
            if (existingIndex !== -1) {
                return prevCoupon.filter((_, index) => index !== existingIndex);
            }
            const matchIndex = prevCoupon.findIndex(coup => coup.matchid === matchid);
            if (matchIndex !== -1) {
                const newCoupon = [...prevCoupon];
                newCoupon[matchIndex] = { matchid, matchname,datetime, marketid, marketname, oddid, oddname, price };
                return newCoupon;
            }
            return [...prevCoupon, { matchid, matchname,datetime, marketid, marketname, oddid, oddname, price }];
        });
    };

    const isButtonActive = (oddid, matchid) => {
        return coupon.some(coup => coup.oddid === oddid && coup.matchid === matchid);
    };

    useEffect(() => {
        const total = coupon.reduce((acc, item) => acc * item?.price, 1);
        setTotalPrice(total.toFixed(2));
    }, [coupon]);

    const handleTooltipOpen = () => {
        setOpenTool(prev => !prev);
        if (!opentool) {
            setTimeout(handleTooltipClose, 5000);
        }
    };

    const handleTooltipClose = () => {
        setOpenTool(false);
    };
    const removeMatchFromCoupon = (matchid) => {
        setCoupon((prevCoupon) => prevCoupon.filter((item) => item.matchid !== matchid));
      };
    const sendCoupon = () => {
        const accessToken = cookies.serviceToken;
        const config = {
            headers: { Authorization: `Bearer ${accessToken}` },
        };
        const params = { descriptionar: coupon, mode: "Athena", type: "LAY", stake: winvalue };
        axios.post("https://api.youbetshop.com/api/coupon/createcoupon", params, config)
            .then(response => {
                if (response.data.data.success) {
                    setMessage("Kuponunuz Oluşturulmuştur");
                    setOpen(true);
                    setStat("success");
                    setWinvalue('');
                    handlePopoverClose();
                    setCoupon([]);
                } else {
                    setMessage(response.data.data.message);
                    setOpen(true);
                    setStat("error");
                }
                recheck();
            })
            .catch(error => {
                console.error('Error creating coupon:', error);
                setMessage(error.response.data.message);
                setOpen(true);
                setStat("error");
            });
    };

    const formatNumber = (number) => {
        return new Intl.NumberFormat('tr-TR', { style: 'decimal', minimumFractionDigits: 2 }).format(number);
    };

    useEffect(() => {
        if (winvalue > 0) {
            setBalance(formatNumber((nowbalance - (winvalue * (totalPrice - 1))).toFixed(2)));
            setBlockBalance(formatNumber((nowblockbalance + (winvalue * (totalPrice - 1))).toFixed(2)));
        } else {
            setBalance(formatNumber(nowbalance));
            setBlockBalance(formatNumber(nowblockbalance));
        }
    }, [winvalue, nowbalance, nowblockbalance, totalPrice]);

    const filterSearch = (e) => {
        const searchTerm = e.toLowerCase();
        if (!e) {
            setFData(data);
        } else {
            const filteredData = data.filter(item => item.name && item.name.toLowerCase().includes(searchTerm));
            setFData(filteredData);
        }
    };

    const handlePopoverOpen = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const openPopover = Boolean(anchorEl);

    return (
        <>
            <AppBar position="static">
                <Toolbar>
                    <IconButton edge="start" color="inherit" aria-label="back" onClick={() => {
                        navigate("/");
                        setBalance(formatNumber(nowbalance));
                        setBlockBalance(formatNumber(nowblockbalance));
                    }}>
                        <ArrowBackIcon />
                        <Typography variant="h6">
                            Ana Sayfa
                        </Typography>
                    </IconButton>
                    <Typography variant="h6" style={{ flexGrow: 1 }} />
                    <Typography variant="body1" style={{ marginRight: '10px' }}>
                        {"Komisyon: %" + athenafee}
                    </Typography>
                </Toolbar>
            </AppBar>
            <Container sx={{ marginBottom: "30px", marginTop: "50px", backgroundColor: "#ece4d4", padding: "20px", borderRadius: "15px" }}>
                <Box
                    my={4}
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginBottom: '15px',
                    }}
                >
                    <Box
                        ml={2}
                        textAlign="left"
                        sx={{
                            display: 'flex',
                            alignItems: { xs: 'flex-start', md: 'center' },
                            justifyContent: 'flex-start',
                        }}
                    >
                        <img
                            src={athena}
                            alt="Athena"
                            style={{ width: '150px', height: '150px', borderRadius: '10px' }}
                        />
                        <Box
                            ml={2}
                            textAlign="left"
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                            }}
                        >
                            <Typography variant="h4" display="flex" sx={{ alignItems: 'center' }}>
                                Athena
                                <Tooltip
                                    title={
                                        <Typography variant="body2">
                                            Athena'a oynatmak istediğiniz kuponu oluşturun lütfen, Athena kaybederse sizden %40 discount ister.
                                        </Typography>
                                    }
                                    arrow
                                    PopperProps={{ disablePortal: true }}
                                    onClose={handleTooltipClose}
                                    open={opentool}
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                >
                                    <IconButton onClick={handleTooltipOpen}>
                                        <HelpOutlineIcon />
                                    </IconButton>
                                </Tooltip>
                            </Typography>
                            <Typography>Athena Oynayabileceği Bülten</Typography>
                        </Box>
                    </Box>
                    <Box
                        textAlign="left"
                        sx={{
                            alignItems: { xs: 'flex-start', md: 'center' },
                            justifyContent: { xs: 'flex-start', md: 'flex-end' },
                            mt: { xs: 2, md: 0 },
                        }}
                    >
                        <Typography sx={{ fontSize: '0.7rem' }}>
                            Bültende Bulamadığınız Maçları Buradan Arayabilirsiniz.
                        </Typography>
                        <Box
                            ml={2}
                            textAlign="left"
                            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginLeft: "0px" }}
                        >
                            <TextField label="Maç Arama" onChange={(e) => filterSearch(e.target.value)} sx={{ width: '100%' }} />
                        </Box>
                    </Box>
                </Box>

                <TableContainer component={Paper}>
                    {isMobile ?
                       filteredData?.map((item, index) => (
                            <Paper key={index}
                                sx={{
                                    backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#ece4d4',
                                    transition: 'height 0.3s ease',
                                    borderTop: "solid 1px #d79f09"
                                }}>
                                <Grid container sx={{ padding: "15px" }}>
                                    <Grid item xs={6} md={6} sm={6} lg={6}>
                                        <Typography>{item.name}</Typography>
                                    </Grid>
                                    <Grid item xs={6} md={6} sm={6} lg={6} sx={{ textAlign: 'right' }}>
                                        <Typography>{dateformat(item.openDate)}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={12} sm={12} lg={12} >
                                        <Grid container >
                                            <Grid item xs={5} md={5} sm={5} lg={5} >
                                                <Typography sx={{ textAlign: 'center', fontSize: "0.75rem" }}>{"1 X 2"}</Typography>
                                            </Grid>
                                            <Grid item xs={3.5} md={3.5} sm={3.5} lg={3.5} >
                                                <Typography sx={{ textAlign: 'center', fontSize: "0.75rem" }}>{"Karşılıklı Gol"}</Typography>
                                            </Grid>
                                            <Grid item xs={3.5} md={3.5} sm={3.5} lg={3.5} >
                                                <Typography sx={{ textAlign: 'center', fontSize: "0.75rem" }}>{"Üst/Alt 2.5"}</Typography>
                                            </Grid>
                                        </Grid></Grid>
                                    <Grid item xs={12} md={12} sm={12} lg={12} >
                                        <Grid container >
                                            <Grid item xs={5} md={5} sm={5} lg={5} >
                                                {item.marketCatalogues.map((market) => market.marketName === "Maç Sonucu" && (
                                                    <>

                                                        <Box key={market.marketId} sx={{ textAlign: "center" }}>

                                                            <Button variant={isButtonActive(market.runners[0].selectionId, item.id) ? 'contained' : 'outlined'} style={{ padding: "0px", minWidth: "43px", fontSize: "0.65rem" }} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name,item.openDate,market.marketId, market.marketName, market.runners[0].selectionId, market.runners[0].runnerName, market.runners[0].ex.availableToLay[0]?.price) }}>
                                                                1<br />{market.runners[0].ex.availableToLay[0]?.price}
                                                            </Button>
                                                            <Button variant={isButtonActive(market.runners[2].selectionId, item.id) ? 'contained' : 'outlined'} style={{ padding: "0px", minWidth: "43px", fontSize: "0.65rem" }} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name,item.openDate,market.marketId, market.marketName, market.runners[2].selectionId, market.runners[2].runnerName, market.runners[2].ex.availableToLay[0]?.price) }}>
                                                                X<br />{market.runners[2].ex.availableToLay[0]?.price}
                                                            </Button>
                                                            <Button variant={isButtonActive(market.runners[1].selectionId, item.id) ? 'contained' : 'outlined'} style={{ padding: "0px", minWidth: "43px", fontSize: "0.65rem" }} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name,item.openDate,market.marketId, market.marketName, market.runners[1].selectionId, market.runners[1].runnerName, market.runners[1].ex.availableToLay[0]?.price) }}>
                                                                2<br />{market.runners[1].ex.availableToLay[0]?.price}
                                                            </Button>
                                                        </Box>
                                                    </>
                                                ))}
                                            </Grid>
                                            <Grid item xs={3.5} md={3.5} sm={3.5} lg={3.5} >
                                                {item.marketCatalogues.map((market) => market.marketName === "Karşılıklı Gol" && (
                                                    <>
                                                        <Box key={market.marketId} sx={{ textAlign: "center" }}>
                                                            <Button variant={isButtonActive(market.runners[0].selectionId, item.id) ? 'contained' : 'outlined'} style={{ padding: "0px", minWidth: "43px", fontSize: "0.65rem" }} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name,item.openDate,market.marketId, market.marketName, market.runners[0].selectionId, market.runners[0].runnerName, market.runners[0].ex.availableToLay[0].price) }}>
                                                                Var<br />{market.runners[0].ex.availableToLay[0]?.price}
                                                            </Button>
                                                            <Button variant={isButtonActive(market.runners[1].selectionId, item.id) ? 'contained' : 'outlined'} style={{ padding: "0px", minWidth: "43px", fontSize: "0.65rem" }} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name,item.openDate,market.marketId, market.marketName, market.runners[1].selectionId, market.runners[1].runnerName, market.runners[1].ex.availableToLay[0]?.price) }}>
                                                                Yok<br />{market.runners[1].ex.availableToLay[0]?.price}
                                                            </Button>
                                                        </Box>
                                                    </>
                                                ))}
                                            </Grid>
                                            <Grid item xs={3.5} md={3.5} sm={3.5} lg={3.5} >
                                                {item.marketCatalogues.map((market) => market.marketName === "Üst/Alt 2.5" && (
                                                    <>
                                                        <Box key={market.marketId} sx={{ textAlign: "center" }}>
                                                            <Button variant={isButtonActive(market.runners[0].selectionId, item.id) ? 'contained' : 'outlined'} style={{ padding: "0px", minWidth: "43px", fontSize: "0.65rem" }} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name,item.openDate,market.marketId, market.marketName, market.runners[0].selectionId, market.runners[0].runnerName, market.runners[0].ex.availableToLay[0]?.price) }}>
                                                                Alt<br />{market.runners[0].ex.availableToLay[0]?.price}
                                                            </Button>
                                                            <Button variant={isButtonActive(market.runners[1].selectionId, item.id) ? 'contained' : 'outlined'} style={{ padding: "0px", minWidth: "43px", fontSize: "0.65rem" }} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name,item.openDate,market.marketId, market.marketName, market.runners[1].selectionId, market.runners[1].runnerName, market.runners[1].ex.availableToLay[0]?.price) }}>
                                                                Üst<br />{market.runners[1].ex.availableToLay[0]?.price}
                                                            </Button>
                                                        </Box>
                                                    </>
                                                ))}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        ))
                        :
                        <Table >
                            <TableHead sx={{ backgroundColor: "#d5d5d5" }} >
                                <TableRow  >
                                    <TableCell>Maç Adı</TableCell>
                                    <TableCell>Tarih</TableCell>
                                    <TableCell sx={{ textAlign: "center" }}>1 X 2</TableCell>
                                    <TableCell sx={{ textAlign: "center" }}>Karşılıklı Gol</TableCell>
                                    <TableCell sx={{ textAlign: "center" }}>2.5 Üst/Alt</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredData?.map((item, index) => (
                                    <TableRow key={item.id}
                                        sx={{
                                            backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#ece4d4',
                                            transition: 'height 0.3s ease',
                                        }}
                                    >
                                        <TableCell>{item.name}</TableCell>
                                        <TableCell>{dateformat(item.openDate)}</TableCell>
                                        <TableCell style={{ padding: "0px" }}>
                                            {item.marketCatalogues.map((market) => market.marketName === "Maç Sonucu" && (
                                                <Box key={market.marketId} sx={{ textAlign: "center" }}>
                                                    <Button variant={isButtonActive(market.runners[0].selectionId, item.id) ? 'contained' : 'outlined'} style={{ padding: "0px", minWidth: "50px" }} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name,item.openDate,market.marketId, market.marketName, market.runners[0].selectionId, market.runners[0].runnerName, market.runners[0].ex.availableToLay[0]?.price) }}>
                                                        1<br />{market.runners[0].ex.availableToLay[0]?.price}
                                                    </Button>
                                                    <Button variant={isButtonActive(market.runners[2].selectionId, item.id) ? 'contained' : 'outlined'} style={{ padding: "0px", minWidth: "50px" }} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name,item.openDate,market.marketId, market.marketName, market.runners[2].selectionId, market.runners[2].runnerName, market.runners[2].ex.availableToLay[0]?.price) }}>
                                                        X<br />{market.runners[2].ex.availableToLay[0]?.price}
                                                    </Button>
                                                    <Button variant={isButtonActive(market.runners[1].selectionId, item.id) ? 'contained' : 'outlined'} style={{ padding: "0px", minWidth: "50px" }} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name,item.openDate,market.marketId, market.marketName, market.runners[1].selectionId, market.runners[1].runnerName, market.runners[1].ex.availableToLay[0]?.price) }}>
                                                        2<br />{market.runners[1].ex.availableToLay[0]?.price}
                                                    </Button>
                                                </Box>
                                            ))}
                                        </TableCell>
                                        <TableCell style={{ padding: "0px" }}>
                                            {item.marketCatalogues.map((market) => market.marketName === "Karşılıklı Gol" && (
                                                <Box key={market.marketId} sx={{ textAlign: "center" }}>
                                                    <Button variant={isButtonActive(market.runners[0].selectionId, item.id) ? 'contained' : 'outlined'} style={{ padding: "0px", minWidth: "50px" }} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name,item.openDate,market.marketId, market.marketName, market.runners[0].selectionId, market.runners[0].runnerName, market.runners[0].ex.availableToLay[0].price) }}>
                                                        Var<br />{market.runners[0].ex.availableToLay[0]?.price}
                                                    </Button>
                                                    <Button variant={isButtonActive(market.runners[1].selectionId, item.id) ? 'contained' : 'outlined'} style={{ padding: "0px", minWidth: "50px" }} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name,item.openDate,market.marketId, market.marketName, market.runners[1].selectionId, market.runners[1].runnerName, market.runners[1].ex.availableToLay[0]?.price) }}>
                                                        Yok<br />{market.runners[1].ex.availableToLay[0]?.price}
                                                    </Button>
                                                </Box>
                                            ))}
                                        </TableCell>
                                        <TableCell style={{ padding: "0px" }}>
                                            {item.marketCatalogues.map((market) => market.marketName === "Üst/Alt 2.5" && (
                                                <Box key={market.marketId} sx={{ textAlign: "center" }}>
                                                    <Button variant={isButtonActive(market.runners[0].selectionId, item.id) ? 'contained' : 'outlined'} style={{ padding: "0px", minWidth: "50px" }} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name,item.openDate,market.marketId, market.marketName, market.runners[0].selectionId, market.runners[0].runnerName, market.runners[0].ex.availableToLay[0]?.price) }}>
                                                        Alt<br />{market.runners[0].ex.availableToLay[0]?.price}
                                                    </Button>
                                                    <Button variant={isButtonActive(market.runners[1].selectionId, item.id) ? 'contained' : 'outlined'} style={{ padding: "0px", minWidth: "50px" }} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name,item.openDate,market.marketId, market.marketName, market.runners[1].selectionId, market.runners[1].runnerName, market.runners[1].ex.availableToLay[0]?.price) }}>
                                                        Üst<br />{market.runners[1].ex.availableToLay[0]?.price}
                                                    </Button>
                                                </Box>
                                            ))}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>}
                </TableContainer>

                <Box mt={4} textAlign="center">
                    <Grid container spacing={2}>
                        <Grid item xs={4} sm={4}>
                            <Typography variant="h6">Toplam Oran:</Typography>
                            <Typography variant="h4" color="primary">{totalPrice}</Typography>
                        </Grid>
                        <Grid item xs={4} sm={4}>
                            <Typography variant="h6">Kazanç:</Typography>
                            <TextField type="number" value={winvalue} onChange={(e) => setWinvalue(e.target.value)} variant="outlined" />
                            <Typography variant="body2" color="textSecondary" onClick={() => setWinvalue(((balance / (totalPrice - 1)).toFixed(2)))}>Max Alınabilir: <span style={{ textDecoration: "underline", color: "#d79f09", cursor: "pointer" }}>{totalPrice > 1 && (Number(nowbalance) / (totalPrice - 1)).toFixed(2)}</span></Typography>
                        </Grid>
                        <Grid item xs={4} sm={4}>
                            <Typography variant="h6">Bloke Tutarı:</Typography>
                            <Typography variant="h4" color="secondary">{winvalue ? (winvalue * (totalPrice - 1)).toFixed(2) : 0}</Typography>
                        </Grid>
                    </Grid>
                </Box>

                <Box mt={4} textAlign="center" sx={{ marginBottom: "20px" }}>
                    <Button variant="contained" color="primary" onClick={sendCoupon}>Kuponu Gönder</Button>
                </Box>
            </Container>
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    severity={stat}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {message}
                    <Button sx={{ color: "#ece4d4", textDecoration: "underline" }} onClick={() => navigate("/my-bets")}>
                        Bahislerim
                    </Button>
                </Alert>
            </Snackbar>

            <Fab
    color="primary"
    aria-label="invoice"
    onClick={handlePopoverOpen}
    sx={{ position: 'fixed', bottom: 16, right: 16 }}
>
    <Badge
        badgeContent={coupon.length}
        color="secondary"
        sx={{
            '& .MuiBadge-badge': {
                position: 'absolute',
                right: -6,
                top: -6,
                backgroundColor: '#000',
                color: '#fff',
                borderRadius: '50%',
                width: '20px',
                height: '20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '0.75rem',
            }
        }}
    >
        <InvoiceIcon />
    </Badge>
</Fab>


            <Popover
    open={openPopover}
    anchorEl={anchorEl}
    onClose={handlePopoverClose}
    disableScrollLock={true} 
    anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
    }}
    transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
    }}
    PaperProps={{
        sx: {
            width: { xs: "100%", sm: "25%" }
        }
    }}
    ref={popoverRef} // Popover'a ref ekleyin
>
    <Grid container sx={{ padding: "10px" }}>
    {coupon.length > 0 ? (
        <>
          {coupon.map((item, index) => (
            <Grid container key={index} sx={{ fontSize: "0.8rem", padding: "5px", borderBottom: "solid 1px #d79f09", alignItems: "center" }}>
              <Grid item xs={5} sm={5}>
                {item.matchname}
              </Grid>
              <Grid item xs={2} sm={2}>
                {item.marketname}
              </Grid>
              <Grid item xs={3} sm={3}>
                {item.oddname}
              </Grid>
              <Grid item xs={1} sm={1}>
                {item.price}
              </Grid>
              <Grid item xs={1} sm={1}>
              <IconButton onClick={() => removeMatchFromCoupon(item.matchid)}>
                <DeleteIcon />
              </IconButton>
              </Grid>
            </Grid>
          ))}
          {/* Ekstra Grid Bileşeni */}
          <Grid container sx={{ fontSize: "0.8rem", padding: "5px", alignItems: "center" }}>
            <Grid item xs={4.5} sm={4.5}>
              Toplam Blokaj Tutarı:
            </Grid>
            <Grid item xs={3.5} sm={3.5}>
            <Typography sx={{  color: "#d79f09",fontWeight:"800"}}>  {winvalue ? (winvalue * (totalPrice - 1)).toFixed(2) : 0}</Typography>
            </Grid>
            <Grid item xs={3} sm={3}>
            {"Toplam Oran:"}
            </Grid>
            <Grid item xs={1} sm={1}>
            <Typography sx={{  color: "#d79f09",fontWeight:"800"}}> {totalPrice}</Typography>
            </Grid>
            <Grid item xs={6} sm={6}>
            <TextField
      type="number"
      value={winvalue}
      label="Kazanç Tutarınız"
      sx={{
        width: "100%",
        marginTop: "5px",
        "& .MuiOutlinedInput-notchedOutline": {
          top: "2px",
        },
        "& .MuiInputBase-input": {
          padding: "9px",
        },
      }}
      onChange={(e) => setWinvalue(e.target.value)}
      variant="outlined"
    />
            </Grid>
            <Grid item xs={6} sm={6}>
            <Button variant="contained" color="primary" sx={{width:"100%",marginTop:"12px",marginLeft:"5px",padding:"4px 16px"}}  onClick={sendCoupon}>Kuponu Gönder</Button>
            </Grid>
          </Grid>
        </>
      ) : (
        <Grid container sx={{ padding: "5px", borderBottom: "solid 1px #d79f09", alignItems: "center" }}>
          <Typography sx={{ textAlign: "center", color: "#d79f09" }}>
            Kuponunuz da Hiç Maç Yok
          </Typography>
        </Grid>
      )}
    </Grid>
</Popover>


        </>
    );
};

export default Athena;
