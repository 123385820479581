import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Alert, IconButton, InputAdornment, Link, CircularProgress } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import axios from 'axios';
import { useCookies } from 'react-cookie';

const LoginDialog = ({ open, onClose, setIsLoggedIn, setUsername, setBalance,setMailVerify,setTelegramVerify,setNotify}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [message, setMessage] = useState('');
  const [stat, setStat] = useState('error');
  const [cookies, setCookie] = useCookies(['serviceToken']);
  const [forgotPasswordOpen, setForgotPasswordOpen] = useState(false);
  const [forgotEmail, setForgotEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  };

  const handleLogin = () => {
    axios.post("https://api.youbetshop.com/login", { email: email, pass: password,user_type:2 })
      .then(response => {
        if (response.data.success) {
          setMessage("Giriş Başarılı");
          setIsLoggedIn(true);
          setOpenSnack(true);
          setCookie('serviceToken', response.data.data.token);
          setCookie('email', response.data.data.email);
          setBalance(response.data.data.balance);
          setUsername(response.data.data.email);
          setTelegramVerify(response.data.data.telegram_verification)
          setMailVerify(response.data.data.email_verification)
          setNotify(response.data.data.ticket_admin_res)
          setStat("success");
          onClose();
        } else {
          setMessage(response.data.data.message);
          setOpenSnack(true);
          setStat("error");
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setMessage("Giriş Başarısız");
        setOpenSnack(true);
        setStat("error");
      });
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleLogin();
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleForgotPasswordOpen = () => {
    setForgotPasswordOpen(true);
  };

  const handleForgotPasswordClose = () => {
    setForgotPasswordOpen(false);
  };

  const handleForgotPasswordSubmit = () => {
    setLoading(true);
    axios.post("https://api.youbetshop.com/forgotpass", { email: forgotEmail })
      .then(response => {
        if (response.data.success) {
          setMessage("Şifre sıfırlama e-postası gönderildi");
          setStat("success");
        } else {
          setMessage("Şifre sıfırlama işlemi başarısız");
          setStat("error");
        }
        setOpenSnack(true);
        setForgotPasswordOpen(false);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error sending forgot password email:', error);
        setMessage("Şifre sıfırlama işlemi başarısız");
        setOpenSnack(true);
        setStat("error");
        setForgotPasswordOpen(false);
        setLoading(false);
      });
  };

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Giriş Yap</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="E-posta"
            type="email"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={handleKeyPress}
          />
          <TextField
            margin="dense"
            label="Şifre"
            type={showPassword ? 'text' : 'password'}
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={handleKeyPress}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <Link
            component="button"
            variant="body2"
            onClick={handleForgotPasswordOpen}
            style={{ display: 'block', marginTop: 8 }}
          >
            Şifremi Unuttum
          </Link>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">İptal</Button>
          <Button onClick={handleLogin} color="primary">Giriş Yap</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={forgotPasswordOpen} onClose={handleForgotPasswordClose}>
        <DialogTitle>Şifremi Unuttum</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="E-posta"
            type="email"
            fullWidth
            value={forgotEmail}
            onChange={(e) => setForgotEmail(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleForgotPasswordClose} color="primary">İptal</Button>
          <Button onClick={handleForgotPasswordSubmit} color="primary" disabled={loading}>
            {loading ? <CircularProgress size={24} /> : 'Gönder'}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={openSnack}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={stat}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default LoginDialog;
